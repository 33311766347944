import { createStore } from 'effector';

import { applyReducers } from '@metropolis-io/effector-utils';
import type { DoneHandler } from '@metropolis-io/effector-utils';

import { getUser, login, requestLoginLink, logout } from './actions';

import type { Effect } from 'effector';
import type { APIResponse } from 'utils/http';

import type { User } from 'types/api';

export type UserState = {
  authenticated: boolean | null;
  user: User | null;
  loginLinkRequested: { email: string } | null;
};

const initialState: UserState = {
  authenticated: null,
  user: null,
  /** Contains { email: string } if a login request was made */
  loginLinkRequested: null,
};

const store = createStore(initialState);

function handleLoginDone(
  state: UserState,
  {
    result: { success, data },
  }: {
    result: {
      success: boolean;
      data?: { user: User };
    };
  },
) {
  return {
    ...state,
    authenticated: !!success,
    user: success ? data!.user : state.user,
  };
}

type Reducers = {
  getUser: {
    action: Effect<Parameters<typeof getUser>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getUser>[0], UserState>;
  };
  login: {
    action: Effect<Parameters<typeof login>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof login>[0], UserState>;
  };
  requestLoginLink: {
    action: Effect<Parameters<typeof requestLoginLink>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof requestLoginLink>[0], UserState>;
  };
  logout: {
    action: Effect<Parameters<typeof logout>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof logout>[0], UserState>;
  };
};

export const reducers: Reducers = {
  getUser: {
    action: getUser,
    done: handleLoginDone,
  },
  login: {
    action: login,
    done: handleLoginDone,
  },
  requestLoginLink: {
    action: requestLoginLink,
    done: (state, { params: { email }, result: { success, data } = {} }) => ({
      ...state,
      loginLinkRequested: { email },
    }),
  },
  logout: {
    action: logout,
    done: (state, { result: { success } = {} }) =>
      success
        ? {
            ...state,
            authenticated: false,
            user: null,
          }
        : state,
  },
};

export default applyReducers({ store, reducers });
