import ReactDOM from 'react-dom';

import PortalApp from 'apps/portal';
import { setCurrentAppName } from 'utils/currentApp';
import { initDatadog } from 'utils/datadog';
import { setTokenName } from 'utils/http';

import * as serviceWorker from './serviceWorker';

import 'antd/es/style/css';

if (!window.ResizeObserver) {
  /* eslint-disable global-require */
  window.ResizeObserver = require('resize-observer-polyfill');
  /* eslint-enable global-require */
}

setCurrentAppName('portal');
setTokenName('portal');
initDatadog('portal');

ReactDOM.render(<PortalApp />, document.getElementById('root'));

document.getElementById('initial-loader')?.remove();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
