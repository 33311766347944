import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import theme, { WebFonts } from '@metropolis-io/theme';

import Portal from './Portal';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <WebFonts />
      <BrowserRouter>
        <Portal />
      </BrowserRouter>
    </ThemeProvider>
  );
}
